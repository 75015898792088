<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="384"
    height="256"
    viewBox="0 0 1149.9 1351.15"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="dd" data-name="dd">
        <g id="_3" data-name="3">
          <g id="CHAIR">
            <path
              class="cls-1"
              d="M313.08,899.61l157.77,170.12a74.16,74.16,0,0,1,28.5,58.43V1307.1a41.05,41.05,0,0,1-41.05,41.05H253.08"
            />
            <path
              class="cls-2"
              d="M498.93,872.61h0a27,27,0,0,1-27,27H82.08A79.09,79.09,0,0,1,3,820.53V511.12a53.94,53.94,0,0,1,54-53.95H257.17A36.82,36.82,0,0,1,294,494V766.54a79.07,79.07,0,0,0,79.07,79.07h98.86A27,27,0,0,1,498.93,872.61Z"
            />
            <path
              class="cls-3"
              d="M497.86,880.15a27,27,0,0,1-25.93,19.46H82.08A79.09,79.09,0,0,1,3,820.53V511.12A54,54,0,0,1,32.52,463,53.73,53.73,0,0,0,24,492.12V813.64a67,67,0,0,0,67,67h402A25.91,25.91,0,0,0,497.86,880.15Z"
            />
            <path
              class="cls-1"
              d="M295,660.89H369A129.77,129.77,0,0,1,498.72,790.66v90"
            />
            <path
              class="cls-1"
              d="M142.08,899.61l157.77,170.12a74.16,74.16,0,0,1,28.5,58.43V1307.1a41.05,41.05,0,0,1-41.05,41.05H82.08"
            />
          </g>
          <g id="CHARACTER">
            <g id="RIGHT_HAND" data-name="RIGHT HAND">
              <path
                id="HAND"
                class="cls-4"
                d="M316.59,251.56s73.88-11,118.27,86.06c38.88,85,67.87,164.27,67.87,164.27s59.92,33.51,160.7,69.38v48.1s-153.71,8.93-205,0c-37.71-6.56-141.77-146.14-149-240.82S316.59,251.56,316.59,251.56Z"
              />
              <path
                id="ARM"
                class="cls-5"
                d="M643.21,618.9c-5.69-1.15-1.54-18.84,1.8-29.75,1.85-6,6.27-9.08,12.16-11.67,11.72-5.18,32-8.2,43.43-5.7,16.78,3.67,27.56,11.14,38,23.35,3,3.49,17.75,20.28,17.75,24.76Z"
              />
            </g>
            <path
              id="TORSO"
              class="cls-6"
              d="M245.84,212.51c-63.7,6.56-186.67,83.9-166.32,367.89C92.52,762,211.75,853,226.16,846.82S485.08,720.13,429.07,655.3c-53.59-62-36.15-109.44-27.72-193.4s21.1-175.33-47-206.95C244,203.74,262.87,210.75,245.84,212.51Z"
            />
            <g id="LEGS">
              <g id="RIGHT_LEG" data-name="RIGHT LEG">
                <g id="SHOES">
                  <path
                    class="cls-7"
                    d="M756.3,1346.83H582.12c-.18-2.78-.23-5.42-.19-8H756.5A40.68,40.68,0,0,1,756.3,1346.83Z"
                  />
                  <path
                    class="cls-8"
                    d="M599.87,1265.55c.62,19.82-17.74,32.37-17.74,73.32H756.3s2.53-34.35-32.3-42.27-66.5-18.5-69.67-31Z"
                  />
                </g>
                <g id="PANT">
                  <path
                    class="cls-7"
                    d="M412.56,643.28S678.4,625.94,743,705.19s-60.8,391.1-73.47,562.83c0,0-63.61,6.6-89.44,0s-48.31-339.37,16.76-419.94c0,0-237.42,59.35-333.87-15.51S372.42,644.68,412.56,643.28Z"
                  />
                </g>
              </g>
              <g id="LEFT_LEG" data-name="LEFT LEG">
                <g id="SHOE">
                  <path
                    class="cls-9"
                    d="M1149.9,1071.84l-138.16,110.59c-2-2.49-3.72-4.93-5.31-7.3L1144.05,1065A39.69,39.69,0,0,1,1149.9,1071.84Z"
                  />
                  <path
                    class="cls-8"
                    d="M977.29,1106c12.06,15.32,4.82,36.94,28.72,69.42l138.17-110.58s-18-28.86-50.3-13-63.55,27.56-73.39,19.61Z"
                  />
                </g>
                <g id="PANT-2" data-name="PANT">
                  <path
                    class="cls-9"
                    d="M218.76,840.53c4,22.37,220.23,86.58,460.9-71.93,0,0,37.34,209.08,280.55,347.78,0,0,53.55-23.8,68.11-55.84,0,0-171.57-219.29-232.66-397C770,589,447.26,628,400.08,643.28S201,740.85,218.76,840.53Z"
                  />
                </g>
              </g>
            </g>
            <g id="LEFT_HAND" data-name="LEFT HAND">
              <path
                id="HAND-2"
                data-name="HAND"
                class="cls-10"
                d="M227.31,285.17C283,303.67,361.38,468.46,361.38,468.46s87.72,8.41,220.48,64c0,0,3.06,28.89-14.43,52.56,0,0-146,18.92-221.94,11.15C319.81,593.52,222.5,520.73,169,420,121.16,329.9,159.28,262.58,227.31,285.17Z"
              />
              <g id="ARM-2" data-name="ARM">
                <path
                  class="cls-11"
                  d="M580.83,533.38s37-19.54,54.33-15.63,38.21,19.54,40.38,23.88,17.37,25.62,17.37,30.39-49.12,3-56,3-52.33.73-61.1-1.09S580.83,533.38,580.83,533.38Z"
                />
                <path
                  class="cls-5"
                  d="M636.87,575.06c6.92,0,56,1.74,56-3s-15.2-26-17.37-30.39-23-20-40.38-23.88c0,0,21.44,29.77,1.71,57.31"
                />
              </g>
            </g>
            <g id="HEAD">
              <path
                class="cls-11"
                d="M300.37,122.5c1.11,8.64-18.16,52.1-52.54,85.89-30.17,29.63,99.6,52.56,103,51.81s18.55-46.38,21.66-57.07S300.37,122.5,300.37,122.5Z"
              />
              <path
                class="cls-5"
                d="M372.48,203.13c-1.34,4.61-6.7,19.67-12.63,35.37-14.32-6.93-31.09-20.51-44.21-46.36-14.21-28-12.44-56.61-14.14-64a8.44,8.44,0,0,1,.64-4C313.46,134.79,375.3,193.41,372.48,203.13Z"
              />
              <path
                class="cls-11"
                d="M412.35,77.82c5.41,21.55,10.17,57.68,2.81,75.22s-20.35,49.77-25.44,51.46-61.08-5.65-74.08-30-13.58-44.68-15.27-52,28.84-66.74,67.86-65.61S410.78,71.57,412.35,77.82Z"
              />
              <path
                class="cls-8"
                d="M436,67.13c-10.37,4.25-2.51,16.28-22,18.08s-24-22.09-24-22.09S387.78,72.67,378,75.93c0,0,5.21-10.12-2.26-14.58s-12.06,1.51-12.06,1.51-6.51-3.26-10,4.52,2.8,12.44,11,13.33c0,0,.75,11.56-17.59,13.32s-22.12-9.55-22.12-9.55-3-2.77-4.28,5.28,5,21.11-7.79,31.42c-5.11,4.11-8,5.74-9.53,6.21,6.45,5.58,3.33,22.52-11.29,29.43-19.85,9.39-34.41,2.45-36.22-11.27-1.63-12.39,6.27-7.53,4.25-17.08,0,0-12.93,9.46-21.3-4.32-6.84-11.26,5.12-18.31,5.12-18.31S235.41,94.16,242.49,86c8-9.31,20.51-3.28,18.68-9.06S254.56,57.58,262.34,50s20.12-4.53,21.87-8.55-.74-10-8.78-6.51a17.92,17.92,0,0,1,8-12.84c7.13-4.81,17.7-3.69,25-1.55C309.67,14,312.76,6,320.66,2.28,333.75-3.85,343.09,3.62,348.2,10a12.21,12.21,0,0,1,10.4-8.25C374.18-.51,387,3.54,390,16.1c3.17,13.23,26.31-17.27,37.2,8.8,2.23,5.33-3.59,9.54,3,13.83,3.73,2.43,8.52-5.5,4.79-8.28A22.61,22.61,0,0,1,446,40.17C448,43.69,452.71,60.3,436,67.13Z"
              />
            </g>
          </g>
          <path
            id="CHAIR-2"
            data-name="CHAIR"
            class="cls-1"
            d="M24,660.89h90A129.77,129.77,0,0,1,243.72,790.66v90"
          />
          <g id="TABLE">
            <path
              class="cls-12"
              d="M1025.65,605.06H509.92a14.5,14.5,0,0,0-14.5,14.5h0a14.5,14.5,0,0,0,14.5,14.5h515.73Z"
            />
            <path
              class="cls-13"
              d="M1025.65,605.06H509.92a14.5,14.5,0,0,0-14.5,14.5h0a14.5,14.5,0,0,0,14.5,14.5h515.73"
            />
            <path
              class="cls-1"
              d="M802.36,1347.15,885.62,649.6c2.64-14.87,23.8-15.34,27.09-.6l103.72,694.24"
            />
          </g>
          <g id="LAPTOP">
            <rect
              class="cls-14"
              x="563.87"
              y="578.22"
              width="416.28"
              height="25.56"
              rx="9.78"
            />
            <path
              class="cls-14"
              d="M954.25,577.78H663.47c-7.52,0-12.57-7.23-9.54-13.67l82.14-174.72c4.66-9.9,15.13-16.29,26.69-16.29h290.79c7.52,0,12.56,7.23,9.53,13.67L980.94,561.48C976.29,571.38,965.82,577.78,954.25,577.78Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
.cls-1,
.cls-13 {
  fill: none;
}
.cls-1,
.cls-13,
.cls-14,
.cls-2,
.cls-3 {
  stroke: #d8baa3;
  stroke-miterlimit: 10;
  stroke-width: 6px;
}
.cls-1,
.cls-2,
.cls-3 {
  stroke-linecap: round;
}
.cls-12,
.cls-14,
.cls-2 {
  fill: #fff;
}
.cls-3 {
  fill: #f0e1d4;
}
.cls-4 {
  fill: #374486;
}
.cls-5 {
  fill: #fab6ad;
}
.cls-6 {
  fill: #3f4d90;
}
.cls-7 {
  fill: #383c4d;
}
.cls-8 {
  fill: #ffb000;
}
.cls-9 {
  fill: #41465a;
}
.cls-10 {
  fill: #49549a;
}
.cls-11 {
  fill: #ffcfc9;
}
</style>
