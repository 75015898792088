<template>
  <div class="cover">
    <div
      class="flex min-h-screen flex-col justify-center bg-white py-12 sm:px-6 lg:px-8"
    >
      <div class="bg-white-50 flex justify-center">
        <div class="left">
          <IllustrationFive />
        </div>
        <div class="right text-left">
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset your password
          </h2>
          <form class="space-y-6 pt-6 text-left">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div class="mt-1">
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  v-model="username"
                  autocomplete="email"
                  required
                  class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
            </div>
          </form>
          <div class="mt-8 flex justify-between">
            <button
              type="button"
              @click="goToLogin"
              class="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Go to Login
            </button>
            <button
              @click.prevent="onSubmit"
              v-loading.fullscreen.lock="fullscreenLoading"
              class="focus:outline-none flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
            >
              Submit Request
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import IllustrationFive from "@/common/components/svg/IllustrationFive";

export default {
  data() {
    return {
      loading: false,
      username: null,
    };
  },
  components: {
    IllustrationFive,
  },
  methods: {
    onSubmit() {
      const url =
        process.env.VUE_APP_API_LOCATION +
        `SystemUsers/RequestPasswordReset?username=${this.username}`;

      axios
        .post(url)
        .then(() => {
          this.$notify({
            title: "Success",
            message: "Request has been sent.",
            type: "success",
          });
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          this.$notify.warning({
            title: "Request",
            message: "Not found",
          });
        });
    },

    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.left {
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgba(249, 250, 251, 1);
  border-radius: 0.375rem; */
  margin-right: 3rem;
  padding: 4rem;
}

.right {
  width: 30%;
  padding: 2rem;
}
</style>
